import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "account-details"
};
const _hoisted_2 = {
  class: "account-details__nav"
};
const _hoisted_3 = {
  class: "account-details__box"
};
const _hoisted_4 = {
  class: "account-details__content"
};
import { router } from '@/router';
import { ref } from 'vue';
import RechargeHistory from '@/components/RechargeHistory.vue';
import WithdrawalHistory from '@/components/WithdrawalHistory.vue';
export default {
  __name: 'AccountDetails',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const type = ref('recharge');
    const changeType = typeVal => {
      type.value = typeVal;
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-grey__bg",
        fixed: "",
        placeholder: "",
        title: "Account details",
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass({
          'active': type.value === 'recharge'
        }),
        onClick: _cache[0] || (_cache[0] = $event => changeType('recharge'))
      }, _toDisplayString(_ctx.$t('Recharge')), 3), _createElementVNode("div", {
        class: _normalizeClass({
          'active': type.value === 'withdraw'
        }),
        onClick: _cache[1] || (_cache[1] = $event => changeType('withdraw'))
      }, _toDisplayString(_ctx.$t('Withdraw')), 3)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [type.value === 'recharge' ? (_openBlock(), _createBlock(RechargeHistory, {
        key: 0
      })) : _createCommentVNode("", true), type.value === 'withdraw' ? (_openBlock(), _createBlock(WithdrawalHistory, {
        key: 1
      })) : _createCommentVNode("", true)])])]);
    };
  }
};