import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { BillList } from '@/api';
import { ref } from 'vue';
export default {
  __name: 'WithdrawalHistory',
  setup(__props) {
    const list = ref([]);
    const page = ref(1);
    const loading = ref(false);
    const finished = ref(false);
    const _BillList = () => {
      BillList({
        type: 'withdraw',
        perPage: 10,
        page: page.value
      }).then(({
        data
      }) => {
        list.value = list.value.concat(data.data);
        loading.value = false;
        finished.value = data.current_page >= data.last_page;
        if (!finished.value) {
          page.value++;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createBlock(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "loading-text": "Loading...",
        "finished-text": "No more data",
        onLoad: _BillList
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            class: "account-details__item",
            key: item.id
          }, [_createElementVNode("span", null, "₹ " + _toDisplayString(item.real_amount), 1), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(item.state ? 'Completed' : 'Pending'), 1), _createElementVNode("p", null, _toDisplayString(item.created_at), 1)])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished"]);
    };
  }
};